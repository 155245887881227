import * as React from "react";
import { useIntl } from "react-intl";
import { useFormikContext } from "formik";
import { Radio, Input, Popup, Image } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import {
  BasicFormProps,
  ConfigProperties,
  SocialNetworksId,
  dateKey,
  socialIcons,
  socialNames,
  timeKey,
  PromptType,
} from "../../../../../interfaces";
import { usePlayerSocialNetworks, useSocialNetworks } from "../../../../../reducers";

import {
  PSTimeZone,
  changeLocalDateToPST,
  filterPassedTime,
  isToday,
  nowInUTC,
  validAge,
  deliveryStatus,
  validateMessageLenghtInSocialNetworks,
} from "../../../../../utils";
import { SocialNetworkIconButton } from "../../../../../components/SocialNetworkIconButton";

import { ScheduledPostType, descriptors } from "./descriptors";

import "../../../../../styling/datepicker.scss";
import { RowBasicPadding, SocialGrid, SocialRowGrid, TitleLabel } from "../../../../../styling/baseStyle";
import { ConfigContext } from "../../../../../contexts/appContexts";
import { PromptComponentType } from "../../../Prompt/descriptors";
import { SegmentedControl } from "../../../../SegmentedController";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue?: (field: string, value: any) => void;
  showPostTypeOption: boolean;
}

type Props = OwnProps;

const dataElmId = "rePostScheduledPost";
/**
 * Represents Scheduled info.
 */
const ScheduledPostClass: React.FC<Props> = ({ handleSetFieldValue, showPostTypeOption = false }) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();
  const config: ConfigProperties = React.useContext(ConfigContext);
  const [multiImage, setMultiImage] = React.useState<boolean | undefined>(undefined);

  const {
    sendMode,
    socialNetworks,
    deliveryDateUtc,
    deliveryTimeUtc,
    isAutoPost,
    messageToPost,
    mediaGroup,
    withMediaReady,
  } = formik.values as any;
  const scheduledMode = sendMode === "SCHEDULED";
  const { sns } = useSocialNetworks();
  const { playerSn } = usePlayerSocialNetworks();

  React.useEffect(() => {
    setMultiImage(mediaGroup ? mediaGroup.type === "MIX" : false);
  }, []);

  const dateSection = (dateEditable: boolean): JSX.Element => {
    moment.tz.setDefault(PSTimeZone);

    const promptDate = deliveryDateUtc ? new Date(deliveryDateUtc) : undefined;
    const promptTime = deliveryTimeUtc ? new Date(deliveryTimeUtc) : undefined;

    if (promptDate !== undefined) {
      const deliveryDate = moment(deliveryDateUtc).tz(PSTimeZone);
      promptDate.setDate(deliveryDate.date());
      promptDate.setMonth(deliveryDate.month());
    }
    if (promptTime !== undefined) {
      const deliveryTime = moment(deliveryTimeUtc).tz(PSTimeZone);

      promptTime.setHours(deliveryTime.hours());
      promptTime.setMinutes(deliveryTime.minutes());
    }

    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);

    return (
      <SocialGrid className={"xbigPaddingTop xxbigPaddingLeft"}>
        <SocialRowGrid>
          <SocialGrid.Column width={6} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="calendar alternate outline" />}
              data-elm-id={dataElmId + "InputSelectedDate"}
              id={dateKey}
              name={dateKey}
              selected={promptDate}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(dateKey, changeLocalDateToPST(date));
                  if (isToday(date)) {
                    handleSetFieldValue!(timeKey, undefined);
                  }
                }
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              maxDate={validAge(yesterday, 1)}
              minDate={nowInUTC()}
              dateFormat="EE, MM/dd/yyyy"
              disabled={!dateEditable}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[ScheduledPostType.datePlaceholder] })}
            />
          </SocialGrid.Column>
          <SocialGrid.Column width={6} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="clock outline" />}
              data-elm-id={dataElmId + "InputSelectedTime"}
              id={timeKey}
              name={timeKey}
              selected={promptTime}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(timeKey, changeLocalDateToPST(date));
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              filterTime={(time: string | number | Date) => filterPassedTime(time, deliveryDateUtc, 1)}
              dateFormat="h:mm aa"
              autoComplete={"off"}
              disabled={!dateEditable}
              placeholderText={formatMessage({ ...descriptors[ScheduledPostType.timePlaceholder] })}
            />
            <span className={"timeZoneSpan"}>
              {formatMessage({ ...descriptors[ScheduledPostType.pstZoneMessage] })}
            </span>
          </SocialGrid.Column>
        </SocialRowGrid>
      </SocialGrid>
    );
  };

  /**
   * Return a custom Radio button with Black Style
   * @param id radio identifier
   * @param disabled if the radio is disabled
   * @param checked if the radio is on
   * @param onChange onChange handler
   * @param label Radio Label key on the descriptor file
   * @returns JSX.Element (Radio element)
   */
  const customRadioButton = (
    id: string,
    disabled: boolean | undefined,
    checked: boolean,
    onChange: (e: any, data: any) => void,
    label: string
  ): JSX.Element => {
    return (
      <Radio
        data-elm-id={`${dataElmId}${id}RadioBtn`}
        className={"blackRadio secondary rounded"}
        id={id}
        name={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        label={formatMessage({ ...descriptors[ScheduledPostType[label]] }, { customMessage: "my" })}
      />
    );
  };

  const autoPostRadio = (): JSX.Element => {
    const radioButton = customRadioButton(
      "isAutoPost",
      multiImage,
      isAutoPost,
      (e: any, data: any) => {
        handleSetFieldValue!(`${data.id}`, data.checked);
      },
      "autoPost"
    );
    let finalMessage = formatMessage({ ...descriptors[ScheduledPostType.multiimageError] });

    return multiImage ? (
      <Popup className="popUpErrorContent" content={finalMessage} trigger={radioButton} />
    ) : (
      radioButton
    );
  };

  const socialNetworkWithValidation = (sn: SocialNetworksId, index: number): JSX.Element => {
    const socialNetworksValidation = validateMessageLenghtInSocialNetworks(messageToPost, config.post!!);

    const messageToLong =
      socialNetworksValidation.includes(socialNames["_" + sn]) ||
      (sn === "TW" && socialNetworksValidation.includes("Twitter"));

    const showNoMediaError = withMediaReady === 0 && (sn === "TT" || sn === "IG" || sn === "IG_STORY");

    let finalMessage = messageToLong
      ? formatMessage(
          {
            ...descriptors[ScheduledPostType.lengthMessageError],
          },
          {
            socialNetwork: socialNames["_" + sn],
          }
        )
      : "";

    if (showNoMediaError) {
      finalMessage =
        finalMessage +
        formatMessage(
          {
            ...descriptors[ScheduledPostType.noMediaError],
          },
          {
            socialNetwork: socialNames["_" + sn],
          }
        );
    }
    return finalMessage !== "" ? (
      <Popup
        className="popUpErrorContent"
        content={finalMessage}
        trigger={<Image src={socialIcons["{" + sn + "}"]} className="disabledSocialNetwork" />}
      />
    ) : (
      socialNetworkButton(sn, index, messageToLong)
    );
  };

  const socialNetworkButton = (sn: SocialNetworksId, index: number, disabled: boolean): JSX.Element => {
    return (
      <SocialNetworkIconButton
        disabled={disabled}
        withPadding={false}
        size={"noMargin"}
        key={"post_sn_icon_" + index}
        active={socialNetworks ? socialNetworks[sn] : false}
        networkId={sn}
        onClickHandler={checked => {
          const snUpdated = { ...socialNetworks, [sn]: checked };
          handleSetFieldValue!("socialNetworksCounter", Object.values(snUpdated).filter(Boolean).length);
          handleSetFieldValue!("socialNetworks", snUpdated);
        }}
      />
    );
  };

  const postTypeOption = () => {
    return (
      <SocialGrid.Row className={"postAsReelColumn"}>
        <SocialGrid.Column width={10}>
          <RowBasicPadding>
            <SegmentedControl
              name="postType"
              callback={(val: string) => {
                handleSetFieldValue!("postType", val);
              }}
              defaultIndex={1}
              controlRef={React.useRef()}
              segments={[
                {
                  label: `Feed Post`,
                  value: "VIDEO",
                  ref: React.useRef(),
                },
                {
                  label: `Reels Post`,
                  value: "REELS",
                  ref: React.useRef(),
                },
              ]}
            />
          </RowBasicPadding>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  /*
   * Auto Post or Push Notification
   */
  const deliveryOption = (): JSX.Element => {
    return (
      <SocialGrid.Row>
        <SocialGrid.Column width={10}>
          <RowBasicPadding>
            <TitleLabel className="titleLabel">
              {formatMessage({ ...descriptors[ScheduledPostType.deliveryOptionLabel] })}
            </TitleLabel>
          </RowBasicPadding>

          <SocialRowGrid className={"bigPaddingTop"} leftpadding={15} key={"brand_post_row_auto_post"}>
            {autoPostRadio()}
            {isAutoPost && (
              <SocialGrid className={"socialSection"}>
                <RowBasicPadding className={"socialIconsSection"}>
                  {sns.filter(sn => playerSn?.includes(sn)).map((sn, index) => socialNetworkWithValidation(sn, index))}
                </RowBasicPadding>
              </SocialGrid>
            )}
          </SocialRowGrid>

          <SocialRowGrid key={"post_row_push_notification"}>
            {customRadioButton(
              "isPushPost",
              false,
              !isAutoPost,
              () => {
                handleSetFieldValue!("isAutoPost", false);
              },
              "noAutoPost"
            )}
          </SocialRowGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const schedulingOptions = (): JSX.Element => {
    const statuses = deliveryStatus(sendMode);

    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <RowBasicPadding>
          <TitleLabel className="titleLabel">
            {formatMessage({ ...descriptors[ScheduledPostType.scheduledOptionsLabel] })}
          </TitleLabel>
          <br />
        </RowBasicPadding>

        <SocialGrid columns={1} className={"bigPaddingTop xbigPaddingLeft mediumPaddingBottom"}>
          {statuses.map((button, index) => {
            return (
              <SocialGrid.Row key={"post_basic_column" + index}>
                {customRadioButton(
                  button.value,
                  false,
                  button.selected,
                  (e: any, data: any) => {
                    handleSetFieldValue!("sendMode", data.name);
                  },
                  button.text
                )}
                {scheduledMode && button.value === sendMode && dateSection(true)}
              </SocialGrid.Row>
            );
          })}
        </SocialGrid>
      </SocialGrid.Row>
    );
  };

  console.log("withMediaReady", withMediaReady);
  return (
    <div key={"rePostFinalStep"} className={"scheduledPostDiv"}>
      {showPostTypeOption && postTypeOption()}
      {deliveryOption()}
      {schedulingOptions()}
    </div>
  );
};

export const ScheduledPostStep = ScheduledPostClass;
