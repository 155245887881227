import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, Grid, Loader, Radio } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../providers";
import { CoachSettings } from "../../interfaces";

import { appSettings } from "./formFields";
import "./styles.scss";
import useCoachSettings from "./settingsHook";
import useAdminCoachSettings from "./adminSettingsHook";
import { useSocialNetworks } from "../../reducers";

interface OwnProps {
  /*
   * Indicate if coach has write permissions
   */
  withWritePermission?: boolean;
  /**
   * Handle Settings changes
   */
  onSettingsChanges: (permissions: { [key: string]: string }) => void;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a AppSettings tab section
 */
const AppSettingsTabFC: React.FC<Props> = ({ withWritePermission, onSettingsChanges }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const { displayTikTok } = useSocialNetworks();
  const { adminSettingLoading, displayAdminSettings } = useAdminCoachSettings(coachId);

  const { settingLoading, handleSettingsChange, permissions } = useCoachSettings(
    coachId,
    displayAdminSettings.postFailed,
    displayAdminSettings.playerReport,
    displayAdminSettings.accessMVP
  );

  React.useEffect(() => {
    if (permissions) {
      onSettingsChanges(permissions);
    }
  }, [permissions]);

  const content = (
    <React.Fragment>
      <Grid columns={1} padded>
        <Grid.Column>
          <Form.Group grouped key={"appSettingsGroupPermissions"}>
            {appSettings
              .filter(s => {
                if (s.name === CoachSettings.SUMMARY_REPORT_PLAYER) {
                  return displayAdminSettings.playerReport === true;
                } else if (s.name === CoachSettings.PLAYER_NOTIFY_FAILED_POST) {
                  return displayAdminSettings.postFailed === true;
                } else if (s.name === CoachSettings.PLAYER_MVP_ACCESS) {
                  return displayAdminSettings.accessMVP === true;
                } else if (s.name === CoachSettings.POST_IN_TT) {
                  return displayTikTok === true;
                } else {
                  return true;
                }
              })
              .map(appSetting => {
                return (
                  <React.Fragment key={appSetting.id + "_fragment"}>
                    <Form.Group inline key={appSetting.id}>
                      <Radio
                        toggle
                        key={appSetting.id}
                        disabled={!withWritePermission}
                        label={appSetting.label}
                        className={"settingsLevelLabel"}
                        onChange={handleSettingsChange}
                        name={appSetting.id}
                        checked={permissions && permissions[appSetting.id] === "ACTIVE"}
                      />
                    </Form.Group>
                  </React.Fragment>
                );
              })}
          </Form.Group>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );

  return (
    <Grid
      columns={16}
      verticalAlign="middle"
      stretched
      container
      textAlign={"left"}
      className={settingLoading || !withWritePermission ? "customFloatedGrid disabledForm" : "customFloatedGrid"}
    >
      <Grid.Column width={16}>
        {settingLoading || adminSettingLoading ? <Loader active size="large" /> : content}
      </Grid.Column>
    </Grid>
  );
};

export const AppSettingsTab = withRouter(AppSettingsTabFC);
