import * as React from "react";
import {
  Card,
  CardContent,
  CardGroup,
  CardHeader,
  CardMeta,
  Grid,
  Icon,
  Image,
  Label,
  Loader,
  Pagination,
} from "semantic-ui-react";
import * as DTO from "../../../interfaces";
import "./styles.scss";
import { ContentPageServices } from "../../../services/ContentPageServices";
import { useEffect, useState } from "react";
import { UIDate } from "../../../utils";
import { VideoPageModal } from "../../../containers/Players/VideoPageModal";
import { ContentPageInfo } from "../../../interfaces";
import { VideoPageStatsPopup } from "../../../containers/Players/VideoPageStatsPopup";

interface Props {
  playerId: number;
  redirectUrl: string;
}

const VideoPagesListFC: React.FC<Props> = ({ playerId, redirectUrl }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [videoPages, setVideoPages] = useState<DTO.ContentPageInfo[]>([]);
  const [showVideoPageModal, setShowVideoPageModal] = React.useState<boolean>(false);
  const [selectedPage, setSelectedPage] = React.useState<ContentPageInfo>();

  useEffect(() => {
    const getPages = async () => {
      setLoading(true);
      const result = await ContentPageServices.searchPages({ playerId }, page, 20);
      if (result.totalPages) setTotalPages(result.totalPages);
      setVideoPages(result.content);
      setLoading(false);
    };

    getPages().catch(console.error);
  }, [page]);

  return (
    <Grid className={"playerVideoPagesList"}>
      <Grid.Row>
        <CardGroup>
          {videoPages.map(page => (
            <Card
              key={`video-page-${page.contentPageId}`}
              onClick={() => {
                setSelectedPage(page);
                setShowVideoPageModal(true);
              }}
            >
              <Image
                src={page.previewMedia && page.previewMedia[0]?.uri}
                className={"videoPageCard"}
                wrapped
                ui={false}
              />
              <CardContent>
                <CardHeader>{page.title || <i>No page title</i>}</CardHeader>
                <CardMeta>{UIDate(page.createdDate || new Date().toLocaleDateString())}</CardMeta>
              </CardContent>
              <CardContent extra>
                <VideoPageStatsPopup
                  page={page}
                  trigger={
                    <Label
                      icon={"eye"}
                      content={`${page?.contentPageViewSummary?.viewCount || 0}`}
                      className={"viewStat"}
                    />
                  }
                />

                {!!page?.cta && (
                  <Label
                    icon={"mouse pointer"}
                    content={`${page?.contentPageViewSummary?.clicks.find(s => s.label == "Clicked")?.value || 0}`}
                    className={"viewStat"}
                  />
                )}
              </CardContent>
            </Card>
          ))}
        </CardGroup>
        {videoPages.length === 0 &&
          (loading ? (
            <Loader active size="large" />
          ) : (
            <div className="resultsEmptyState">
              <Icon name="search" size="huge" />
              <h2>No video pages found.</h2>
            </div>
          ))}
      </Grid.Row>
      <Grid.Row>
        {videoPages.length > 0 && (
          <Pagination
            activePage={page + 1}
            boundaryRange={3}
            pointing
            secondary
            firstItem={null}
            lastItem={null}
            totalPages={totalPages}
            disabled={loading}
            onPageChange={(_, data) => {
              setPage(Number(data.activePage) - 1);
            }}
          />
        )}
      </Grid.Row>

      <VideoPageModal
        opened={showVideoPageModal}
        page={selectedPage}
        showStats={false}
        showTakeDownButton={true}
        title={"SocialCoach Video Page"}
        closeHandler={() => {
          setSelectedPage(undefined);
          setShowVideoPageModal(false);
        }}
        deleteHandler={deletedPage => {
          setVideoPages(pages => {
            return pages.filter(p => p.contentPageId != deletedPage.contentPageId);
          });
        }}
        pageSaveHandler={newPage => {
          setVideoPages(pages => {
            const i = pages.findIndex(p => p.contentPageId === newPage.contentPageId);
            if (i) {
              pages[i] = newPage;
            }
            return pages;
          });
        }}
      />
    </Grid>
  );
};

export const VideoPagesList = VideoPagesListFC;
