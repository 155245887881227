import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Tab } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../../providers";
import { WebComponents, CoachAppSetting, StickerRequest, CoachSettings, TagElement } from "../../../interfaces";
import { AppSettingsTab, SideBar, TitleSection, StickersTab, CoachHashtagsTab } from "../../../components";
import { coachWithWritePermission } from "../../../utils";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";
import "./styles.scss";
import { HashTagsServices, SettingsServices, StickerServices } from "../../../services";

type Props = RouteComponentProps;

const SettingsFC: React.FC<Props> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  const withWritePermission = coachWithWritePermission(WebComponents.APP_SETTINGS, userContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [updatedHashTagsList, setUpdatedHashTagsList] = React.useState<TagElement[] | undefined>(undefined);

  const [appSettings, setAppSettings] = React.useState<{
    [key: string]: string;
  }>({});
  const [stickerRequest, setStickerRequest] = React.useState<StickerRequest | undefined>(undefined);

  const updateSettings = (): void => {
    if (selectedIndex === 0) {
      if (settingsOK()) {
        updateAppSettings();
      } else {
        alert("At least one of the social network toggles should remain enabled.");
      }
    }
    if (selectedIndex === 1) {
      updateStickers();
    } else {
      updateHahstags();
    }
  };

  const settingsOK = (): boolean => {
    // Verify if at least one social network is active
    const someIsActive = Object.keys(appSettings)
      .filter(key => key.includes("postIn"))
      .reduce((result, sn) => result || appSettings[sn] === "ACTIVE", false);
    return someIsActive;
  };

  const updateStickers = (): void => {
    setLoading(true);

    if (stickerRequest) {
      StickerServices.update(coachId, stickerRequest)
        .catch((e: string) => {
          alert(e.substring(0, 40));
        })
        .finally(() => {
          setLoading(false);
          setStickerRequest(undefined);
        });
    }
  };

  const updateHahstags = (): void => {
    if (updatedHashTagsList) {
      setLoading(true);
      HashTagsServices.updateCoachHashtags(coachId, {
        hashtags: updatedHashTagsList.map(hashtag => hashtag.displayValue),
        addHashTagAutomatically: false,
      })
        .catch((e: string) => {
          alert(e.substring(0, 40));
        })
        .finally(() => {
          setLoading(false);
          setStickerRequest(undefined);
        });
    }
  };

  const updateAppSettings = (): void => {
    setLoading(true);

    const settingsRequest: CoachAppSetting = {
      coachId,
      settings: [
        {
          appSettingId: CoachSettings.CUSTOM_STICKERS,
          status: appSettings["stickers"],
        },
        {
          appSettingId: CoachSettings.CUSTOMIZABLE_RANDOM_CAMPAIGN,
          status: appSettings["customCampaigns"],
        },
        {
          appSettingId: CoachSettings.POST_CUSTOM_IMAGES,
          status: appSettings["postCustomImages"],
        },
        {
          appSettingId: CoachSettings.POST_CUSTOM_MESSAGE,
          status: appSettings["postCustomMessage"],
        },
        {
          appSettingId: CoachSettings.PLAYER_STICKERS,
          status: appSettings["playerStickers"],
        },
        {
          appSettingId: CoachSettings.POST_IN_FB,
          status: appSettings["postInFB"],
        },
        {
          appSettingId: CoachSettings.POST_IN_IG,
          status: appSettings["postInIG"],
        },
        {
          appSettingId: CoachSettings.POST_IN_TW,
          status: appSettings["postInTW"],
        },
        {
          appSettingId: CoachSettings.POST_IN_TT,
          status: appSettings["postInTT"],
        },
        {
          appSettingId: CoachSettings.POST_IN_LI,
          status: appSettings["postInLI"],
        },
        {
          appSettingId: CoachSettings.POST_IN_IG_STORY,
          status: appSettings["postInIGStory"],
        },
        {
          appSettingId: CoachSettings.CAMPAIGN_DEFAULT_SN,
          status: appSettings["campaignDefaultSn"],
        },
        {
          appSettingId: CoachSettings.PLAYER_HASHTAG,
          status: appSettings["playerHashtags"],
        },
        {
          appSettingId: CoachSettings.PLAYER_CUSTOM_STICKERS,
          status: appSettings["playerCustomSticker"],
        },
        {
          appSettingId: CoachSettings.PLAYER_NOTIFY_FAILED_POST,
          status: appSettings["playerNotifyFailedPost"],
        },
        {
          appSettingId: CoachSettings.SUMMARY_REPORT_PLAYER,
          status: appSettings["summaryReportPlayers"],
        },
        {
          appSettingId: CoachSettings.RECURRING_ONBOARDING_NOTIFICATIONS,
          status: appSettings["recurringOnboardingNotifications"],
        },
        {
          appSettingId: CoachSettings.RECURRING_SOCIAL_CONNECT_NOTIFICATIONS,
          status: appSettings["recurringSocialConnectNotifications"],
        },
        {
          appSettingId: CoachSettings.COMPLIANCE_DISCLAIMER,
          status: appSettings["complianceDisclaimer"],
        },
        {
          appSettingId: CoachSettings.NOTIFY_PLAYER_VIDEO_PROJECT_EDITED,
          status: appSettings["notifyPlayerVideoProjectEdited"],
        },
        {
          appSettingId: CoachSettings.PLAYER_MVP_ACCESS,
          status: appSettings["playerAccessMVP"],
        },
      ],
    };

    SettingsServices.updateAppSettings(settingsRequest).finally(() => {
      setLoading(false);
    });
  };
  const panes = [
    {
      menuItem: "App Settings",
      render: () => (
        <Tab.Pane>
          <AppSettingsTab onSettingsChanges={setAppSettings} withWritePermission={withWritePermission} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Custom Stickers",
      render: () => (
        <Tab.Pane>
          <StickersTab onChange={setStickerRequest} onLoading={setLoading} withWritePermission={withWritePermission} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Default Hashtags",
      render: () => (
        <Tab.Pane>
          <CoachHashtagsTab onHashtagsChanges={setUpdatedHashTagsList} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Settings"}
            ready={true}
            titleSize={9}
            buttonsSize={7}
            nextButtonSize={9}
            handleNextAction={() => updateSettings()}
            disableButtons={selectedIndex === 1 && !stickerRequest}
            isLoading={loading}
            disableDuringLoading={true}
            customNextLabel={selectedIndex === 1 ? "Update Stickers" : "Update Settings"}
            showNextOption={withWritePermission}
          />

          <Grid.Row className={"settingsListRowContainer leftPadding coachBaseContainer"}>
            <Grid.Column>
              <Grid>
                <Grid.Row>
                  <Tab
                    className={"basicPadding"}
                    panes={panes}
                    onTabChange={(e, data) => {
                      setSelectedIndex(Number(data.activeIndex) || 0);
                    }}
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const SettingsSection = withRouter(SettingsFC);
