import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Menu, MenuItem } from "semantic-ui-react";
import { get } from "lodash";

import { SideBar, TitleSection } from "../../../components";
import { AppContext } from "../../../providers";
import { CoachSettings, LibraryTags, PricePlanTier } from "../../../interfaces";
import { URLS } from "../../../utils";

import { WhiteContainer, ContentGrid, SocialGrid, SocialGridColumn } from "../../../styling/baseStyle";

import { PlayerVideoCatalyst } from "./components/PlayerVideoCatalyst";

import { LibraryType, descriptors } from "./descriptors";
import { RowContainer } from "./styled";
import "./styles.scss";
import useAdminCoachSettings from "../../../components/AppSettingsTab/adminSettingsHook";
import { useMemo } from "react";
import usePlayerSettings from "../../../utils/usePlayerSettings";
import { PlayerVideoScripts } from "./components/PlayerVideoScripts";
import { PlayerPrompts } from "./components/PlayerPrompts";
import { PlayerVideoPages } from "./components/PlayerVideoPages";

interface SettingMenu {
  title: string;
  activeSection: LibraryTags;
}

type Props = RouteComponentProps;

const LibraryFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);

  const myCoachId = get(userContext, "subscriptions[0].pricePlan.coachId", undefined);
  const playerId = get(userContext, "player.id", undefined);
  const subscriptionType: string = get(userContext, "subscriptions[0].pricePlan.tier", "");
  const { hasSetting } = usePlayerSettings(playerId);
  const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;
  const { displayAdminSettings } = useAdminCoachSettings(myCoachId);
  const hasScriptLibrary = useMemo(() => {
    return displayAdminSettings.accessScriptLibrary;
  }, [displayAdminSettings.accessScriptLibrary]);

  const initialTab = (params["tab"] as LibraryTags) || "prompts";
  const [activeSection, setActiveSection] = React.useState<LibraryTags>(initialTab);

  React.useEffect(() => {
    history.replace(URLS.player.library.replace(":tab?", activeSection));
  }, [activeSection]);

  const [menuList, setMenuList] = React.useState<SettingMenu[]>([
    {
      title: "Prompts",
      activeSection: "prompts",
    },
  ]);

  React.useEffect(() => {
    let menu: SettingMenu[] = [
      {
        title: "Prompts",
        activeSection: "prompts",
      },
    ];
    if (isSocialCoachPlus) {
      menu = menu.concat([
        {
          title: "Video Catalyst",
          activeSection: "video-catalyst",
        },
      ]);
    }
    if (isSocialCoachPlus || hasScriptLibrary) {
      menu = menu.concat([
        {
          title: "Scripts",
          activeSection: "scripts",
        },
      ]);
    }

    if (hasSetting(CoachSettings.PLAYER_MVP_ACCESS)) {
      menu = menu.concat([
        {
          title: "Video Pages",
          activeSection: "video-pages",
        },
      ]);
    }

    setMenuList(menu);
  }, [isSocialCoachPlus, hasScriptLibrary, hasSetting]);

  const selectedContent = React.useMemo(() => {
    switch (activeSection) {
      case "prompts":
        return <PlayerPrompts coachId={myCoachId!!} playerId={playerId!!} />;
      case "scripts":
        return (
          <PlayerVideoScripts
            handleNextAction={() => {
              history.push(URLS.player.newVideoScript);
            }}
          />
        );
      case "video-catalyst":
        return (
          <PlayerVideoCatalyst
            myCoachId={myCoachId!!}
            playerId={playerId!!}
            addCampaignHandler={() => {
              history.push(URLS.player.newVideoProject);
            }}
          />
        );
      case "video-pages":
        return <PlayerVideoPages coachId={myCoachId!!} playerId={playerId!!} />;
    }
  }, [activeSection, myCoachId, playerId, history]);

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[LibraryType.title] })}
            ready={true}
            showNextOption={false}
            showSeparator={false}
            titleSize={5}
          />
          <Menu pointing secondary className="internalTapMenu">
            {menuList.map(element => {
              return (
                <MenuItem
                  name={element.title}
                  active={activeSection === element.activeSection}
                  onClick={() => setActiveSection(element.activeSection)}
                />
              );
            })}
          </Menu>

          <RowContainer className="playerListRowContainer">
            <SocialGridColumn rightpadding={"0"}>{selectedContent}</SocialGridColumn>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const PlayerLibrary = withRouter(LibraryFC);
