import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import * as DTO from "../../../interfaces";
import { VideoProjectEditorCardRecord, VideoProjectEditorCard } from "../../../components";
import { AppContext } from "../../../providers";

import "./styles.scss";
import { Checkbox, Dropdown, DropdownItemProps, Icon, Input, Loader, Pagination } from "semantic-ui-react";
import { UsersService, VideoProjectServices } from "../../../services";
import { isEqual, debounce, isEmpty } from "lodash";
import { getStatusText } from "../../../utils";
import { Svg } from "../../../assets";
import { Page } from "../../../components/Page";

const EditingQueueListFC: React.FC<RouteComponentProps> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const currentUserId = userContext!.account.userId;

  const [searchTerm, setSearchTerm] = React.useState<string | null>(new URLSearchParams(location.search).get("search"));
  const statusValues = Object.values(DTO.VideoProjectStatus);
  const statusOptions = statusValues.map(status => ({ key: status, text: getStatusText(status), value: status }));
  const [selectedStatuses, setSelectedStatuses] = React.useState<DTO.VideoProjectStatus[]>(
    localStorage
      .getItem("editingQueueList-selectedStatuses")
      ?.split("|")
      ?.map(el => el as DTO.VideoProjectStatus)
      ?.filter(el => statusValues.includes(el)) || [DTO.VideoProjectStatus.SUBMITTED_FOR_EDITING]
  );
  const [assigneeOptions, setAssigneeOptions] = React.useState<DropdownItemProps[]>([]);
  const [selectedAssignees, setSelectedAssignees] = React.useState<number[]>(
    localStorage
      .getItem("editingQueueList-selectedAssignees")
      ?.split("|")
      ?.map(el => parseInt(el))
      ?.filter(isFinite) || []
  );
  const [includeDemoProjects, setIncludeDemoProjects] = React.useState<boolean>(
    localStorage.getItem("includeDemoProjects") === "true"
  );

  const [loading, setLoading] = React.useState<boolean>(true);
  const [videoProjects, setVideoProjects] = React.useState<DTO.PlayerVideoProjectResponse[]>([]);
  const [editors, setEditors] = React.useState<DTO.Editor[]>([]);
  const [editorUserIds, setEditorUserIds] = React.useState<number[]>(
    [...new Set([...selectedAssignees, currentUserId])].filter(el => el !== -1)
  );
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalElements, setTotalElements] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);

    const assignees =
      selectedAssignees.length > 0 ? selectedAssignees.map(userId => (userId === -1 ? null : userId)) : undefined;
    const filters: DTO.SearchVideoProjectFilter = {
      status: selectedStatuses,
      searchTerm,
      includeDemoProjects,
      assignees,
    };

    VideoProjectServices.getAsEditor("createdDate,DESC", filters, page, 24)
      .then(response => {
        setVideoProjects(response.content);

        let userIds = response.content.flatMap(vp => (vp.assigneeUserId ? [vp.assigneeUserId] : []));
        setEditorUserIds(prevIds => {
          let newIds = [...new Set<number>([...prevIds, ...userIds])];
          return isEqual(prevIds, newIds) ? prevIds : newIds;
        });
        setTotalPages(response.totalPages!);
        setTotalElements(response.totalElements!);
      })
      .finally(() => setLoading(false));
  }, [searchTerm, selectedStatuses, selectedAssignees, includeDemoProjects, page]);

  React.useEffect(() => {
    UsersService.getEditors(editorUserIds)
      .then(({ content }) => {
        const editors = content.sort((a: DTO.Editor, b: DTO.Editor) => {
          if (a.userId === currentUserId) return -1;
          if (b.userId === currentUserId) return 1;
          if ((a.firstName || "") < (b.firstName || "")) return -1;
          if ((a.firstName || "") > (b.firstName || "")) return 1;
          return 0;
        });
        setEditors(editors);
        setAssigneeOptions([
          { key: -1, text: "Unassigned", value: -1 },
          ...editors.map(editor => {
            return {
              key: editor.userId,
              text: `${editor.firstName} ${editor.lastName}`,
              value: editor.userId,
              image: { avatar: true, src: editor.photoUrl || Svg.noImageuser },
            };
          }),
        ]);
      })
      .catch(console.log);
  }, [editorUserIds]);

  const delayedSetSearchTerm = React.useRef(
    debounce((text: string) => {
      setPage(0);
      setSearchTerm(isEmpty(text) ? null : text);
    }, 500)
  ).current;

  const videoProjectsWithAssigneeNames = (
    videoProjects: DTO.PlayerVideoProjectResponse[],
    editors: DTO.Editor[]
  ): VideoProjectEditorCardRecord[] => {
    return videoProjects.map(videoProject => {
      var assigneeName: string | null = null;
      if (videoProject.assigneeUserId) {
        const editor = editors.find(editor => editor.userId === videoProject.assigneeUserId);
        if (editor) assigneeName = `${editor.firstName} ${editor.lastName}`;
        else assigneeName = "...";
      }

      return {
        ...videoProject,
        assigneeName,
      };
    });
  };

  const onSearchTermChange = (e: any) => {
    delayedSetSearchTerm(e.target.value);
  };
  const onSelectedStatusesChange = (_e: any, data: any) => {
    const statuses = data.value as DTO.VideoProjectStatus[];
    localStorage.setItem("editingQueueList-selectedStatuses", statuses.join("|"));
    setPage(0);
    setSelectedStatuses(statuses);
  };
  const onSelectedEditorsChange = (_e: any, data: any) => {
    const assignees = data.value as number[];
    localStorage.setItem("editingQueueList-selectedAssignees", assignees.join("|"));
    setPage(0);
    setSelectedAssignees(assignees);
  };
  const onIncludeDemoProjectsChange = (_e: any, data: any) => {
    localStorage.setItem("includeDemoProjects", `${data.checked}`);
    setPage(0);
    setIncludeDemoProjects(data.checked!!);
  };

  return (
    <Page title="Editing Queue">
      <div className="editingQueueFiltersContainer">
        <Input
          data-elm-id="admin-dashboard-text-search"
          icon="search"
          defaultValue={searchTerm}
          placeholder="Search by player or project ID..."
          onChange={onSearchTermChange}
        />
        <Dropdown
          placeholder="Select a status"
          multiple
          data-elm-id="admin-dashboard-status-selector"
          selection
          value={selectedStatuses}
          options={statusOptions}
          onChange={onSelectedStatusesChange}
        />
        <Dropdown
          placeholder="Select an assignee"
          multiple
          data-elm-id="admin-dashboard-status-selector"
          selection
          value={selectedAssignees}
          options={assigneeOptions}
          onChange={onSelectedEditorsChange}
        />
        <Checkbox
          label="Show test/demo projects"
          checked={includeDemoProjects}
          onChange={onIncludeDemoProjectsChange}
        />
      </div>

      <div className="editingQueueResultsContainer">
        {videoProjects.length > 0 && (
          <div>
            Showing <b>{totalElements}</b> results
          </div>
        )}

        <div data-elm-id="videoProjectListElement" className="videoProjectsGrid">
          {videoProjectsWithAssigneeNames(videoProjects, editors).map(videoProject => (
            <VideoProjectEditorCard
              key={"video_project_card_" + videoProject.videoProjectId}
              videoProject={videoProject}
              disabled={loading}
            />
          ))}
        </div>
        {videoProjects.length === 0 &&
          (loading ? (
            <Loader active size="large" />
          ) : (
            <div className="resultsEmptyState">
              <Icon name="search" size="huge" />
              <h2>No results found</h2>
              <div>Adjust your filters and try again</div>
            </div>
          ))}
        {videoProjects.length > 0 && (
          <Pagination
            activePage={page + 1}
            boundaryRange={3}
            pointing
            secondary
            firstItem={null}
            lastItem={null}
            totalPages={totalPages}
            disabled={loading}
            onPageChange={(_, data) => {
              setPage(Number(data.activePage) - 1);
            }}
          />
        )}
      </div>
    </Page>
  );
};

export const EditorEditingQueueList = withRouter(EditingQueueListFC);
